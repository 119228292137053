<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="订单合并采购明细"
    :visible.sync="show"
    width="1000px"
  >
    <el-form inline ref="filtersForm">
      <el-form-item label="供应商" prop="storeId">
        <el-select
          v-model="currentSupplier"
          placeholder="请选择供应商"
          @change="supplierChange"
          clearable
        >
          <el-option
            v-for="item in supplierOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="logisticsData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="supplierName"
        label="供应商"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="barNo"
        label="商品条形码"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="inPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="payPrice"
        label="金额小计(元)"
      ></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
      <el-button
        :disabled="!logisticsData.length"
        :loading="btnLoading"
        type="primary"
        @click="mergeOrderConfirm"
        >确认合并
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['sendOrderData'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      supplierOptions: [],
      currentSupplier: '',
      suppliers: null,
      logisticsData: [],
      backUpData: []
    }
  },
  created() {
    this.getLogisticsData()
  },
  methods: {
    supplierChange(value) {
      if (!value) {
        this.logisticsData = this.backUpData
      } else {
        this.logisticsData = this.backUpData.filter(
          item => item.supplierName == value
        )
      }
    },
    mergeOrderConfirm() {
      this.$confirm(
        '请确认对当前所选门店采购订单进行合并下单，一旦操作将不可进行逆转！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(async () => {
        this.btnLoading = true

        try {
          await this.$api.storeProcurementOrders.orderConsolidation(
            this.sendOrderData
          )

          this.$message.success('合并成功！')
          this.show = false
          this.$emit('getData')
        } catch (e) {
          console.log(e)
        } finally {
          this.btnLoading = false
        }
      })
    },
    async getLogisticsData() {
      this.logisticsLoading = true

      try {
        this.loading = true
        const r = await this.$api.storeProcurementOrders.getLogisticsData(
          this.sendOrderData
        )
        const needData = r.value.reduce((arr, item) => {
          let targetArr = arr.concat(item)
          return targetArr
        }, [])

        this.logisticsData = needData
        this.backUpData = needData

        const arr = []

        needData.forEach(item => {
          if (arr.indexOf(item.supplierName) < 0) {
            arr.push(item.supplierName)
          }
        })

        this.supplierOptions = arr
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
