<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="currentTitle"
    :visible.sync="show"
    width="400px"
  >
    <div class="content" v-loading="loading">
      <el-form
        size="small"
        label-width="100px"
        :rules="rules"
        :model="confirmForm"
        ref="confirmFormRef"
      >
        <template v-if="currentTitle == '确认收款'">
          <el-form-item label="收款金额：">
            <span>￥{{ confirmForm.payPrice || 0 }}</span>
          </el-form-item>
          <el-form-item label="收款日期：" prop="payDate">
            <el-date-picker
              v-model="confirmForm.payDate"
              type="date"
              placeholder="请选择收款日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="收款备注：">
            <el-input
              style="width: 220px"
              type="textarea"
              resize="none"
              row="3"
              placeholder="收款备注"
              v-model="confirmForm.remark"
              maxlength="120"
            />
          </el-form-item>
        </template>

        <template v-if="currentTitle == '收款记录'">
          <el-form-item label="收款金额：">
            <span>￥{{ confirmForm.confirmPrice || '0' }}</span>
          </el-form-item>
          <el-form-item label="收款日期：">
            <span>{{ confirmForm.payDate || '-' }}</span>
          </el-form-item>
          <el-form-item label="收款备注：">
            <span>{{ confirmForm.remark || '-' }}</span>
          </el-form-item>
          <el-form-item label="确认收款人：">
            <span>{{ confirmForm.confirmName || '-' }}</span>
          </el-form-item>
          <el-form-item label="确认时间：">
            <span>{{ confirmForm.confirmTime || '-' }}</span>
          </el-form-item>
        </template>

        <template v-if="currentTitle == '付款记录'">
          <el-form-item label="付款金额：">
            <span>￥{{ confirmForm.payPrice || '0' }}</span>
          </el-form-item>
          <el-form-item label="付款日期：">
            <span>{{ confirmForm.payTime || '-' }}</span>
          </el-form-item>
          <el-form-item label="付款备注：">
            <span>{{ confirmForm.payRemark || '-' }}</span>
          </el-form-item>
          <el-form-item label="付款人：">
            <span>{{ confirmForm.payName || '-' }}</span>
          </el-form-item>
        </template>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
      <el-button
        type="primary"
        v-if="currentTitle == '确认收款'"
        :loading="btnLoading"
        @click="confirmCollectHandler"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['currentTitle', 'currentNo', 'currentPayPrice'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      rules: {
        type: [{ required: true, trigger: 'blur', message: '请选择配送方式' }],
        logisticsCompany: [
          { required: true, trigger: 'blur', message: '请输入快递公司' }
        ],
        logisticsNo: [
          { required: true, trigger: 'blur', message: '请输入快递单号' }
        ],
        carNo: [{ required: true, trigger: 'blur', message: '请输入车牌号' }],
        payDate: [
          { required: true, trigger: 'change', message: '请选择收款日期' }
        ],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!/^1[\d | *]{10}$/.test(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      confirmForm: {
        payPrice: '',
        payDate: '',
        remark: ''
      }
    }
  },
  created() {
    if (this.currentTitle == '收款记录') {
      this.queryReceipt()
    }
    if (this.currentTitle == '付款记录') {
      this.queryPayRecord()
    }
    if (this.currentTitle == '确认收款') {
      this.confirmForm.payPrice = this.currentPayPrice
    }
  },
  methods: {
    async queryReceipt() {
      try {
        const r = await this.$api.storeProcurementOrders.queryReceipt({
          purchaseNo: this.currentNo
        })

        r.entity.payDate = this.$utils.dateFormat(
          r.entity.payDate,
          'YYYY-MM-DD'
        )
        this.confirmForm = r.entity
      } catch (e) {
        console.log(e)
        this.confirmForm = {
          payPrice: '',
          payDate: '',
          remark: ''
        }
      } finally {
        this.loading = false
      }
    },
    async queryPayRecord() {
      try {
        const r = await this.$api.storeProcurementOrders.queryPayRecord({
          purchaseNo: this.currentNo
        })

        this.confirmForm = r.entity
      } catch (e) {
        console.log(e)
        this.confirmForm = {
          payPrice: '',
          payDate: '',
          remark: ''
        }
      } finally {
        this.loading = false
      }
    },
    async confirmCollectHandler() {
      this.$refs.confirmFormRef.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          try {
            await this.$api.storeProcurementOrders.confirmReceipt({
              purchaseNo: this.currentNo,
              payDate: this.$utils.dateFormat(
                this.confirmForm.payDate,
                'YYYY-MM-DD'
              ),
              remark: this.confirmForm.remark
            })

            this.$message.success('确认收款成功！')
            this.confirmCollectDialog = false
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
